<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			@rowClick="rowClick"
			showOrder
			:activeTab="activeTab"
			custom
		/>
	</div>
</template>

<script>
/* 单项评估 */
import baseTable from '@/views/components/components-base-table';
import moment from 'moment';
import { setUrlParams } from '@/utils/util';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'singleEvaluation',
	components: {
		baseTable
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		statusOpts() {
			return this.getOptsById('innerTalentStatus');
		}
	},
	data() {
		return {
			tableCols: [
				{
					label: '计划名称',
					key: 'planName'
				},
				{
					label: '公司名称',
					key: 'company'
				},
				{
					label: '被评估人',
					key: 'userName'
				},
				{
					label: '组织',
					key: 'firstOrg'
				},
				{
					label: '职位',
					key: 'officeName'
				},
				{
					label: '项目经理',
					key: 'projectManagerName'
				},
				{
					label: '评估人',
					key: 'evaluateUserName'
				},
				{
					label: '复核人',
					key: 'reviewUserName'
				},
				{
					label: '截止时间',
					key: 'endTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{
					label: '评估状态',
					key: 'status',
					width: 120,
					formatter: (v) => this.statusOpts.find(({ dictId }) => dictId === v)?.dictName
				},
				{
					label: '操作',
					key: 'cz',
					width: '120px',
					fixed: 'right',
					render: (row) => {
						const { planStatus, isProjectManager, isAdmin } = row;
						const noEditBtn = (['2'].includes(planStatus) && isProjectManager && !isAdmin) || ['3', '4'].includes(planStatus);

						return (
							<div style="display:inline-flex;align-items: center;height: 22px">
								{this.permissionControlBtns(this.pageName, 'Edit') && !noEditBtn && (
									<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
										编辑
									</el-button>
								)}
							</div>
						);
					}
				}
			],
			queryItem: [
				{
					label: '计划名称',
					key: 'planId',
					type: 'selectSearch',
					onlyOnceRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/internal/talent/getPlanList', { request: { pageNo: 1, pageSize: 40, planName: query } })
							.then((res) => res.list?.map(({ planName, id }) => ({ dictId: id, dictName: planName }))),
					span: 6
				},
				{
					label: '状态',
					key: 'status',
					type: 'select',
					span: 6,
					options: () => this.statusOpts
				},
				// { label: '时间范围', key: 'date', type: 'daterange', span: 6 },
				{ label: '姓名', key: 'userName', type: 'input', span: 6 },
				{ label: '职位', key: 'officeName', type: 'input', span: 6 },
				// { label: '项目经理', key: 'projectManager', type: 'input', span: 6 },
				{
					label: '项目经理',
					key: 'projectManager',
					showKey: 'projectManagerName',
					type: 'selectSearch',
					span: 6,
					firstRequest: true, // 开始直接请求一次
					onlyOnceRequest: true,
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '1' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name }))),
					rules: () => this.rules.select,
					formatter: (e, { projectManagerName }) => projectManagerName
				},
				{ label: '评估人', key: 'evaluateUserName', type: 'input', span: 6 },
				{ label: '复核人', key: 'reviewUserName', type: 'input', span: 6 }
			],

			formModel: {}
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			params.request.evaluationType = this.activeTab;
			callback(this.$axios.post('/v1/internal/talent/selectUser', params));
		},
		// 点击table行
		rowClick({ planId, id }) {
			this.$router.push({
				name: 'EvaluationDetail',
				query: { params: setUrlParams({ userId: id, planId, type: 'view', pageName: this.pageName }) }
			});
		},
		handleEdit({ planId, id }, e) {
			e.stopPropagation();
			this.$router.push({
				name: 'EvaluationDetail',
				query: { params: setUrlParams({ userId: id, planId, type: 'edit', pageName: this.pageName }) }
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>
