<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="完整评估" name="0" v-if="permissionControl('AllEvaluation')">
				<AllEvaluation ref="pane1" pageName="AllEvaluation" v-if="activeName === '0'"></AllEvaluation>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="胜任度评估" name="1" v-if="permissionControl('CompetencyAssessment')">
				<singleEvaluation ref="pane2" pageName="CompetencyAssessment" activeTab="1" v-if="activeName === '1'"></singleEvaluation>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="潜力素质评估" name="2" v-if="permissionControl('QualityAssessment')">
				<singleEvaluation ref="pane3" pageName="QualityAssessment" activeTab="2" v-if="activeName === '2'"></singleEvaluation>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
/* 评估中心 */
import AllEvaluation from './allEvaluation'; // 评估列表
import singleEvaluation from './singleEvaluation'; // 评估列表

// import * as api from "@/api/workbench";
export default {
	name: 'assessmentCenter',
	components: {
		AllEvaluation,
		singleEvaluation
	},
	data() {
		return {
			activeName: '1',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		const { activeName, listQuery } = data || {};

		if (activeName) {
			this.activeName = activeName;
		}
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '0', key: 'AllEvaluation' },
			{ name: '1', key: 'CompetencyAssessment' },
			{ name: '2', key: 'QualityAssessment' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`pane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>
